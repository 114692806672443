/* .SignUpButton {
  background: rgb(153, 77, 0);
  border-radius: 3px;
  border: 2px solid rgb(195, 116, 51);
  color: rgb(195, 116, 51);
  margin: 0.5em 1em;
  padding: 0.25em 1em;
  font-size: 2rem;
  border-radius: 15px;
  text-decoration: none;
} */
a {
  text-decoration: none;
  color: rgb(153, 77, 0);
}

.App {
  text-align: center;
  /* background-color: rgb(228,206,221); */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
@media only screen and (min-width: 420px) {
  .stars {
    display: flex;
    /* border: 2px solid black; */
    width: 28%;
    margin: 0 auto;
  }
}
.starss {
  width: 12%;
  margin: 0 auto;
  display: flex;
  /* border: 2px solid black; */
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.FPW {
  text-align: left;
}

.DropDown {
  margin-left: 125%;
  width: 5.2rem;
}

.DropDownTwo {
  width: 5.2rem;
}
