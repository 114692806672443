/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.navbar {
  overflow: hidden;
  background-color: rgb(153, 77, 0);
  font-family: Arial;
}

.navbar a {
  float: left;
  font-size: 16px;
  color: #d3b8b8;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.dropdown {
  float: left;
  overflow: hidden;
}

.dropdown .dropbtn {
  font-size: 16px;
  border: none;
  outline: none;
  color: #d3b8b8;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}

a {
  color: #d3b8b8;
}

.navbar a:hover,
.dropdown:hover .dropbtn {
  background-color: rgb(153, 77, 0);
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #d6a567;
  min-width: 160px;
  box-shadow: #d3b8b8;
  z-index: 1;
}

.dropdown-content a {
  float: none;
  color: rgb(153, 77, 0);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.search-map {
  /* background: #ffffff url(images/search.png) no-repeat 4px 4px; */
  padding: 4px 4px 4px 22px;
  /* border: 68px solid #d3b8b8; */
  width: 767px;
  height: 700px;
  /* position: absolute; */
  margin: 90px;
  right: 200px;
  margin: 0 auto;
}

html {
  background-color: rgb(153, 77, 0);
}
h1 {
  color: #d3b8b8;
  border-top: 20px;
  /* width: 97%; */
  padding: 39px;
  border-width: 2px;
  border-color: #d3b8b8;
  border-style: solid;
}
@media (max-width: 500px) {
  .hair-video {
    width: 500px;
    margin: 0 auto;
    padding: 15px;
  }
}
input {
  font-family: "Arial";
  color: rgb(153, 77, 0);
  /* background-color: #d3b8b8; */
  font-size: 10pt;
  /* width: 100px; */
  padding: 13px;
  margin: 0 auto;
  display: flex;
  align-items: center;
}
.review-button {
  background: transparent;
  border-radius: 3px;
  border: 2px solid rgb(195, 116, 51);
  color: rgb(195, 116, 51);
  margin: 8.5em 1em;
  padding: 0.25em 1em;
  font-size: 2rem;
  border-radius: 15px;
}
@media (max-width: 500px) {
  .review-text {
    padding: 60px;
    font-size: 17px;
    /* display: flex; */
    border-width: 1px;
    border-color: #cccccc;
    /* background-color: #ffffff; */
    color: rgb(153, 77, 0);
    border-style: solid;
    border-radius: 0px;
    box-shadow: 0px 0px 5px rgba(66, 66, 66, 0.75);
    width: 216px;
  }
}

/* .submit {
  background: transparent;
  border-radius: 3px;
  border: 2px solid rgb(195, 116, 51);
  color: rgb(195, 116, 51);
  margin: 0.5em 1em;
  padding: 0.25em 1em;
  font-size: 2rem;
  border-radius: 15px;
} */
label {
  font-family: "Arial";
  color: rgb(153, 77, 0);
  /* background-color: #d3b8b8; */
  font-size: 15pt;
  width: 100px;
  /* align-content: center; */
  margin: 38px;
}
select {
  /* margin-bottom: 12px;
  padding: 8px;
  background-color: #333333;
  color: Gray; */
  font-family: "Arial";
  color: rgb(153, 77, 0);
  background-color: #d3b8b8;
  font-size: 15pt;
  width: 100px;
  /* align-content: center; */
  margin: 38px;
}
.fix {
  background-color: #d6a567;
}
.submit {
  background-color: #d6a567;
}

.form {
  margin: 0 auto;
  /* display: flex; */
  align-items: center;
  padding-bottom: 50px;
  padding: 103px;
  font-size: 20px;
  border-width: 2px;
  border-color: #d3b8b8;
  color: rgb(153, 77, 0);
  background-color: #d6a567;
  color: #000000;
  border-style: solid;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(187, 177, 177, 0.71);
}
form {
  margin: 0 auto;
  align-items: center;
  padding: 22px;
  font-size: 20px;
  border-width: 2px;
  border-color: #d3b8b8;
  color: rgb(153, 77, 0);
  background-color: #d6a567;
  color: #000000;
  border-style: solid;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(187, 177, 177, 0.71);
  /* position: absolute; */
  top: 322px;
  /* height: 550px; */
  width: 472px;

  justify-content: space-around;
}
.button {
  color: #d6a567;
}
button {
  color: rgb(153, 77, 0);
  background-color: #d6a567;
}
.card {
  position: relative;
  font-weight: 400;
  padding: padding;
  /* position: absolute; */
  background: #fff;
}

.material-icons {
  background: transparent;
  border-radius: 3px;
  border: 2px solid rgb(195, 116, 51);
  color: rgb(83, 70, 60);
  /* margin: -2.5em 0em; */
  padding: 0.25em 1em;
  font-size: 2rem;
  border-radius: 15px;
  display: flex;
}

footer {
  /* position: relative */
  margin-top: 723px;
  /* negative value of footer height */
  height: 100px;
  clear: both;
}
.Container-review {
  /* padding: 68px; */
}
.add-review {
  bottom: 0;
}
.review-card {
  width: 20px;
}
.text {
  text-align: center;
  margin: 0 auto;
  margin-top: 9%;
  width: 95%;
}
* {
  font-family: Jura, Arial;
  font-weight: 400;
}
body {
  margin: 0;
  padding: 0;
}
nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 10vh;
  /* background: rgb(228, 206, 221); */
  color: rgb(195, 116, 51);
}

.nav-links {
  width: 50%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style: none;
}

.PicSubmit {
  color: red;
}

.NewPic {
  font-size: 1rem;
  display: flex;
  flex-direction: column;
}
.DropDown {
  align-items: center;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  /* margin: 0; */
}
section#entry-page {
  display: grid;
  grid-template-columns: 1fr minmax(200px, 400px) 1fr;
  grid-template-rows: 1fr minmax(auto, 1fr) 1fr;
  grid-gap: 10px;
  width: 100%;
  height: 100vh;
  background: linear-gradient(-45deg, #e4cedd, #c37433);
  background-size: 400% 400%;
  animation: Gradient 15s ease infinite;
  box-sizing: border-box;
}
section#entry-page form {
  grid-column: 2;
  grid-row: 2;
  display: grid;
  grid-gap: 10px;
  margin: auto 0;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  box-shadow: 0 32px 64px rgba(0, 0, 0, 0.2);
}
section#entry-page form h2 {
  margin-bottom: 5px;
  text-align: center;
  text-shadow: 0 4px 16px #fff;
  font-size: 30px;
  font-weight: 100;
}
section#entry-page form fieldset {
  margin: 0;
  background-color: #fff;
  border: none;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}
section#entry-page form fieldset legend {
  padding: 5px;
  background-color: #fff;
  border-radius: 5px;
}
section#entry-page form fieldset ul {
  margin: 0;
  padding: 0;
}
section#entry-page form fieldset ul li {
  display: grid;
  align-items: center;
  margin: 10px;
}
section#entry-page form fieldset ul li a {
  color: #02c;
}
section#entry-page form fieldset ul li em {
  grid-column: span 2;
  text-align: center;
  padding: 5px;
}
section#entry-page form fieldset ul li label {
  text-align: left;
  padding-bottom: 2px;
}
section#entry-page form fieldset ul li input {
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
}
section#entry-page form fieldset ul li input:hover {
  border: 1px solid #aaf;
}
section#entry-page form button {
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}
section#entry-page form button:hover {
  background-color: #eef;
  border: 1px solid #aaf;
}

@media only screen and (min-width: 420px) {
  form h2 {
    font-size: 40px;
  }
  form fieldset ul li {
    grid-template-columns: 100px 1fr;
  }
  form fieldset ul li label {
    padding-right: 10px;
    padding-bottom: 0;
    text-align: right !important;
  }
}
@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.CustomerProfile {
  display: flex;
  font-size: 2rem;
  margin: 0 auto 0.5%;
  border-radius: 8px;
}
.SignUpCustomer {
  display: flex;
  font-size: 2rem;
  margin: 0 auto 0.5%;
  border-radius: 8px;
}
